import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPrismic } from "@prismicio/vue";
import "@/assets/styles/bootstrap.min.css";
import PageHeader from "@/components/ui/PageHeader.vue";
import OscarIcons from "@/components/ui/OscarIcons.vue";
import NavMenu from "@/components/ui/NavMenu.vue";


const prismic = createPrismic({
  endpoint: "https://arepasytamales.cdn.prismic.io/api/v2",
});

const app = createApp(App);
app.use(router);
app.use(prismic);

app.component("PageHeader", PageHeader);
app.component("OscarIcons", OscarIcons);
app.component("NavMenu", NavMenu);
app.mount("#app");
