
<template>
  <img
 
    class="image lazyload"
    v-for="image in addedImage"
    :key="image.id"
    :data-src="image.src"
    :style="image.style"
    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  />
</template>

<script>
export default {
  data() {
    return {
      numbers: [],
      pageHeight: 700,
      iconQuantity: 8,
      addedImage: [],
      imgTop: 0,
      imgLeft: 0,
      imgSize: 40,
      selectedImage: "",
      nextId: 100,
    };
  },
  mounted() {
    this.pageHeight = document.body.scrollHeight;
   

    if (document.body.scrollWidth > 768) {
      this.imgSize = 70;
    }

    this.iconQuantity = Math.min(
      Math.max(parseInt(this.pageHeight / 91), 1),
      90
    );

    this.numbers = this.randomUnique(90, this.iconQuantity);
    let play = this;

    for (let i = 0; i < this.numbers.length; i++) {
      let imgTop = this.randomPos(this.pageHeight - this.imgSize);
      let imgLeft = this.randomPos(window.innerWidth - this.imgSize);

      this.addedImage.push({
        style: {
          top: `${imgTop}px`,
          left: `${imgLeft}px`,
          height: `${this.imgSize}px`,
          width: `${this.imgSize}px`,
        },
        src: require("@/assets/img/icons/" + play.numbers[i] + ".png"),
        id: play.nextId++,
      });
    }
  },

  methods: {
    randomUnique(range, count) {
      let nums = new Set();
      while (nums.size < count) {
        nums.add(Math.floor(Math.random() * (range - 1 + 1) + 1));
      }
      return [...nums];
    },
    randomPos(twoSizes) {
      return Math.round(Math.random() * twoSizes);
    },
 
  },
};
</script>
<style scoped>
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px);
  }
}
.image {
  position: absolute;
  z-index: 10;
  user-select: none;
  overflow: hidden;
  pointer-events:none;
}
</style>




