<template>
  <footer id="navigation">
    <div class="container-fluid mb-3" :class="[showMenu ? 'mt-3 pt-3 pt-md-5 mt-md-5' : '']">
      <h1 v-if="showMenu" class="large-text">Menu:</h1>
      <router-link to="/arepas-y-tamales"
        ><h1 class="large-text">AREPAS Y TAMALES</h1></router-link
      >
      <!-- Projects Extension -->
      <h1 @click="showProjects = !showProjects" class="large-text">PROJECTS</h1>

      <transition
        name="expand"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
      >
        <div v-if="showProjects" class="extended-projects">
          <router-link
            @mouseover="hovering(index)"
            @mouseleave="hovering(null)"
            v-for="(project, index) in projects"
            :key="index"
            :to="`/${project.extension}`"
            :class="[showProjects ? 'project-appear' : 'project-dissapear']"
            class="project-link"
          >
            <h1 class="large-text">
              {{ project.name }}
            </h1></router-link
          >
        </div>
      </transition>

      <router-link to="/info"><h1 class="large-text">INFO</h1></router-link>
      <router-link v-if="showMenu" to="/"
        ><h1 class="large-text name">STUDIO OSCAR MURILLO</h1></router-link
      >
    </div>
    <!-- Hover modal -->
    <div class="hover-wrapper">
      <div
        class="container-fluid h-100 justify-content-center align-items-center d-none d-md-flex"
      >
        <div
          v-for="(preview, index) in projects"
          :key="index"
          class="preview-image"
          :class="[index === 2 ? 'col-5' : 'col-4']"
          v-show="hoverImage === index"
        >
          <div class="h-100 justify-content-center align-items-center d-flex">
            <img class="img-fluid" :src="preview.img" alt="" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      showProjects: false,
      projects: [
        {
          name: "Collective Conscience",
          extension: "collective-conscience",
          img: require("@/assets/img/preview-images/collective-conscience.png"),
        },
        {
          name: "Storm From Paradise",
          extension: "storm-from-paradise",
          img: require("@/assets/img/preview-images/storm-from-paradise.gif"),
        },
        {
          name: "Runway",
          extension: "runway",
          img: require("@/assets/img/preview-images/runway.jpg"),
        },
        {
          name: "Wearable Sculptures",
          extension: "wearable-sculptures",
          img: require("@/assets/img/preview-images/wearable-sculptures.jpg"),
        },
        {
          name: "AYT01 FILTER",
          extension: "ayt01-filter",
          img: require("@/assets/img/preview-images/ayt01-filter-light.jpg"),
        },
        {
          name: "SHIRT",
          extension: "shirt",
          img: require("@/assets/img/preview-images/shirt.png"),
        },
      ],
      hoverImage: null,
    };
  },
  methods: {
    checkRoute() {
      let routeName = this.$route.name;
      if (routeName === "HomeView") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    hovering(i) {
      this.hoverImage = i;
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  created() {
    this.checkRoute();
  },
};
</script>
<style scoped lang="scss">
.preview-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.hover-wrapper {
  pointer-events: none;
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
h1:hover {
  cursor: pointer;
}
.extended-projects {
  margin-left: 1rem;
}
.large-text {
  user-select: none;
}
@media (min-width: 768px) {
  .extended-projects {
    margin-left: 6rem;
  }
}
.name {
  margin-top: 0.5em !important;
}
footer {
  /* height:100%; */
  bottom: 0;
  /* margin-top: auto; */
  color: white;
  text-decoration: none;
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  cursor: pointer;
}
.expand-enter-active {
  transition: all 400ms cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow: hidden;
}

.expand-leave-active {
  overflow: hidden;
}

.expand-enter-from {
  opacity: 0;
  overflow: hidden;
}

.expand-enter-to {
  opacity: 1;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>