import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/arepas-y-tamales',
    name: 'ArepasView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/ArepasView.vue'
      )
  },
  {
    path: '/collective-conscience',
    name: 'CollectiveView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/CollectiveView.vue'
      )
  },
  {
    path: '/storm-from-paradise',
    name: 'StormView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/StormView.vue'
      )
  },
  {
    path: '/runway',
    name: 'RunwayView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/RunwayView.vue'
      )
  },
  {
    path: '/wearable-sculptures',
    name: 'WearableView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/WearableView.vue'
      )
  },
  {
    path: '/wearable-sculptures/:uid',
    name: 'ArtworkView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/ArtworkView.vue'
      ),
    props: true
  },
  {
    path: '/ayt01-filter',
    name: 'FilterView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/FilterView.vue'
      )
  },
  {
    path: '/shirt',
    name: 'ShirtView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/ShirtView.vue'
      )
  },
  {
    path: '/shirt/:uid',
    name: 'ShirtDetailView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/ShirtDetailView.vue'
      ),
    props: true
  },

  {
    path: '/info',
    name: 'InfoView',

    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/InfoView.vue'
      )
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
