<template>
  <ScreenSaver />
  <transition name="load">
    <div v-if="loaded" class="loading-screen">
      <div
        class="container-fluid h-100 d-flex justify-content-center align-items-center flex-column"
      >
        <div :style="{ top: count + '%' }" class="scan-reveal"></div>
        <img class="loading-image" src="@/assets/img/om-loading.jpg" alt="" />
      </div>
    </div>
  </transition>

  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in" appear>
      <component :fullyloaded="!loaded" :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
import ScreenSaver from "@/components/ui/ScreenSaver.vue";
import "lazysizes";
export default {
  components: {
    ScreenSaver,
  },
  data() {
    return {
      count: 1,
      loaded: true,
      time: [],
    };
  },
  methods: {
    preloader() {
      let play = this;

      // Loads the images one at a time, then calls the callback function when all images
      // have been loaded
      const loadImages = (index, callback) => {
        if (index < 40) {
          this.count = index * 4;
          let img = new Image();
          img.src = require("@/assets/img/icons/" + [index] + ".png");

          img.onload = function () {
            loadImages(++index, callback);
          };
        } else {
          callback();
        }
      };
      window.onload = function () {
        loadImages(1, () => {
          setInterval(() => {
            play.loaded = false;
          }, 100);
        });
      };
    },
  },

  mounted() {
    this.preloader();
    visualViewport.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--viewport-height",
        `${visualViewport.height}px`
      );
    });
  },
};
</script>



<style lang="scss">
@font-face {
  font-family: "BB Grotesk";
  src: url("~@/assets/BBGROTESKIII-Regular.woff2") format("woff2"),
    url("~@/assets/BBGROTESKIII-Regular.woff") format("woff");
}

:root {
  --viewport-height: 100%;
}
body {
  height: 100%;
  background: black;
}

html {
  height: 100%;
  background: black;
  margin: 0;
  padding: 0;
}

#app {
  font-family: BB Grotesk, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: black;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  height: calc(var(--viewport-height));
  position: relative;
  min-height: 100%;
}

.screen-wrapper {
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
}
.large-text {
  font-size: 1.8em;
  padding: 0;
  margin: 0;
  line-height: 1.1em;
}

@media (min-width: 576px) {
  .large-text {
    font-size: 2.2em !important;
  }
}

@media (min-width: 768px) {
  .large-text {
    font-size: 4em !important;
  }
}

@media (min-width: 992px) {
  .large-text {
    font-size: 4.6em !important;
  }
}

.route-enter-from,
.route-leave-to {
  opacity: 0;
}
.route-enter-active {
  transition: all 150ms ease-out;
}
.route-leave-active {
  transition: all 100ms ease-in;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
}

.code-text {
  text-align: center;
  font-size: 3.5vw;
}
.project-tags {
  text-align: left;
}
.plyr {
  margin-bottom: 43px;
}

@media (min-width: 576px) {
  .code-text {
    font-size: 4.8vw;
  }
  .plyr {
    margin-bottom: 68px;
  }
}

@media (min-width: 768px) {
  .code-text {
    font-size: 4.3vw;
  }
  .plyr {
    margin-bottom: 97px;
  }
}

@media (min-width: 992px) {
  .code-text {
    font-size: 4.8vw;
  }
  .plyr {
    margin-bottom: 137px;
  }
}
iframe {
  width: 100vw;
  height: calc(100vw / 1.77);
}
// .video-slide .plyr__control svg {
//   width: 70px !important;
//   height: 70px;
// }

.plyr__control--overlaid {
  background: none !important;
}
// .play-button {
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   margin: auto;
//   width: 8rem;
//   color: white;
//   filter: invert(100%);
// }

.plyr--full-ui input[type="range"] {
  color: white !important;
}
.video-slide {
  width: 100%;
  background: #ddcfb2;
  position: relative;
}
.plyr__control {
  border-radius: 0 !important;
}
.plyr__control:hover {
  background: rgba(138, 138, 138, 0.592) !important;
}

video {
  width: 100vw;
  height: auto;
}
video:hover {
  cursor: pointer;
}

.mute-enter-from,
.mute-leave-to {
  opacity: 0;
}
.mute-enter-active {
  transition: all 500ms ease;
}
.mute-leave-active {
  transition-delay: 1500ms;
  transition: all 500ms ease;
}
.mute-enter-to,
.mute-leave-from {
  opacity: 1;
}

#volume-button {
  position: fixed;
  opacity: 0.8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 51;
  width: 7em;
  margin: auto;
  display: block;
}

.button-mix {
  z-index: 55;
}

#volume-button:hover {
  cursor: pointer;
}
.video-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background: black;
  overflow: hidden;
}
.video-container > video {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  z-index: 1;
}

@media (min-aspect-ratio: 16/9) {
  .video-container > video {
    width: 100% !important;
    height: auto;
  }
}

.blur {
  -webkit-animation: blur 10s;
  -moz-animation: blur 10s;
  animation: blur 10s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

@-webkit-keyframes blur {
  0% {
    -webkit-filter: blur(40px);
  }
  0% {
    -webkit-filter: blur(20px);
  }
  50% {
    -webkit-filter: blur(10px);
  }
  100% {
    -webkit-filter: blur(0px);
  }
}

@-moz-keyframes blur {
  0% {
    -moz-filter: blur(40px);
  }
  0% {
    -moz-filter: blur(20px);
  }
  50% {
    -moz-filter: blur(10px);
  }
  100% {
    -moz-filter: blur(0px);
  }
}

@keyframes blur {
  0% {
    filter: blur(40px);
  }
  0% {
    filter: blur(20px);
  }
  50% {
    filter: blur(10px);
  }
  100% {
    filter: blur(0px);
  }
}

// @media (min-width: 992px) {
//   .video-container > video {
//     height: 100%;
//   }
// }

.scan-reveal {
  width: 100%;
  height: 80%;
  background: black;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 20vh;
  bottom: 0;
  transition: all 50ms linear;
  z-index: 40;
  overflow: hidden;
}

@keyframes ellipsis-dot {
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
.loading-image {
  width: 50vh;
}
.loading-screen {
  background: black;
  z-index: 20;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  overscroll-behavior-y: none;
}
.screen-wrapper {
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
}

.load-enter-from,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 300ms ease;
  transition-delay: 400ms;
}
.load-leave-active {
  transition: all 300ms ease;
}
.load-enter-to,
.load-leave-from {
  opacity: 1;
}

.video-enter-from,
.video-leave-to {
  opacity: 0;
}
.video-enter-active {
  z-index: 30;
  position: absolute;
  transition: all 8s ease-out;
  transition-delay: 2s;
}
.video-leave-active {
  transition: all 0ms ease-out;
}
.video-enter-to,
.video-leave-from {
  opacity: 1;
}
</style>
