<template>
  <div class="h-100">
    <HomePage />
    <ExperienceBanner :fullyloaded="fullyloaded" />
  </div>
</template>

<script>
import HomePage from "@/components/home/HomePage.vue";
import ExperienceBanner from "@/components/ui/ExperienceBanner.vue";

export default {
  props: ["fullyloaded"],
  name: "HomeView",
  components: {
    HomePage,
    ExperienceBanner,
  },
};
</script>
