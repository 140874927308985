<template>
  <transition name="video">
    <div
      v-if="showScreensaver"
      class="video-container d-flex justify-content-center align-items-center"
    >
      <div
        @click="showScreensaverBtn"
        :class="[showScreensaver ? 'blur' : '']"
        class="d-flex justify-content-center align-items-center h-100"
      >
        <transition name="mute">
          <div v-if="!muteButtonHide" @mouseover="mouseMute = true" @mouseleave="mouseMute = false" @click="toggleMute" class="button-mix">
            <img
              id="volume-button"
              src="@/assets/img/volume-mute.svg"
              alt=""
            /></div
        ></transition>

        <div
          @click="clearScreensaver"
          v-if="showScreensaver"
          class="clear-overlay"
        ></div>

        <iframe
          id="vimeo-player"
          title="vimeo-player"
          src="https://player.vimeo.com/video/829890548?h=e7d38dfee9&amp;controls=0&amp;loop=1"
          width="640"
          height="360"
          allow="autoplay"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div></div
  ></transition>
</template>
<script setup>
import { watch, onMounted, ref } from "vue";
import Player from "@vimeo/player";

const showScreensaver = ref(false);
const t = ref(null);

const muteButtonHide = ref(false);

let player = null;
const startScreensaver = () => {
  showScreensaver.value = true;
  setTimeout(() => {
    const pla = document.getElementById("vimeo-player");
    player = new Player(pla);

    player.setMuted(true);
    player.setVolume(0);
    player.on("play", handlePlayerPlay);
    player.play();
  }, 2000);
};

const handlePlayerPlay = () => {
  player.setMuted(false);
  player.off("play", handlePlayerPlay);
};

const toggleMute = () => {
  if (player) {
    player.setMuted(false);
    player.setVolume(1);
    muteButtonHide.value = true;
  }
};

const resetTimer = () => {
  if (showScreensaver.value) {
    return;
  } else {
    showScreensaver.value = false;

    clearTimeout(t.value);
    t.value = setTimeout(startScreensaver, 180000);
  }
};

function clearScreensaver() {
  player.setMuted(true);
  player.setVolume(0);
  showScreensaver.value = false;
  muteButtonHide.value = false;
  resetTimer();
}
const mouseMute = ref(false)
function showScreensaverBtn() {
  if(!mouseMute.value) {
    showScreensaver.value = false
  }
}

const idleChecker = () => {
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.ontouchmove = resetTimer;
  window.onclick = resetTimer;
  window.onkeydown = resetTimer;
  window.addEventListener("scroll", resetTimer, true);
  resetTimer();
};

watch(showScreensaver, () => {
  if (showScreensaver.value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
});

onMounted(() => {
  idleChecker();
});
</script>

<style scoped>
.mute-enter-from,
.mute-leave-to {
  opacity: 0;
}
.mute-enter-active {
  transition: all 500ms ease;
}
.mute-leave-active {
  transition-delay: 1500ms;
  transition: all 500ms ease;
}
.mute-enter-to,
.mute-leave-from {
  opacity: 1;
}

#volume-button {
  position: fixed;
  opacity: 0.8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 102;
  width: 7em;
  margin: auto;
  display: block;
}

.button-mix {
  z-index: 102;
  width:7rem;
  height:7rem;
  position:fixed;
    top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin:auto;

}
.clear-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

#volume-button:hover {
  cursor: pointer;
}
.video-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: black;
  overflow: hidden;
}
.video-container > video {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  z-index: 1;
}

@media (min-aspect-ratio: 16/9) {
  .video-container > video {
    width: 100% !important;
    height: auto;
  }
}

.blur {
  -webkit-animation: blur 10s;
  -moz-animation: blur 10s;
  animation: blur 10s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

@-webkit-keyframes blur {
  0% {
    -webkit-filter: blur(40px);
  }
  0% {
    -webkit-filter: blur(20px);
  }
  50% {
    -webkit-filter: blur(10px);
  }
  100% {
    -webkit-filter: blur(0px);
  }
}

@-moz-keyframes blur {
  0% {
    -moz-filter: blur(40px);
  }
  0% {
    -moz-filter: blur(20px);
  }
  50% {
    -moz-filter: blur(10px);
  }
  100% {
    -moz-filter: blur(0px);
  }
}

@keyframes blur {
  0% {
    filter: blur(40px);
  }
  0% {
    filter: blur(20px);
  }
  50% {
    filter: blur(10px);
  }
  100% {
    filter: blur(0px);
  }
}
.video-enter-from,
.video-leave-to {
  opacity: 0;
}
.video-leave-to {
  transition: all 0ms !important;
  transition-delay: 0s !important;
}
.video-enter-active {
  z-index: 30;
  transition: all 8s ease-out;
  transition-delay: 4s;
}
.video-leave-active {
  transition: all 0ms !important;
  transition-delay: 0s;
}
.video-enter-to,
.video-leave-from {
  opacity: 1;
}
</style>