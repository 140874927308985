<template>
  <div class="h-100 d-flex flex-column">
    <div class="container-fluid">
      <OscarIcons />
      <div class="row">
        <div class="col-12 mb-2">
          <span class="home-header">AYT:</span>
        </div>
        <div class="col-12">
          <div class="line"></div>
        </div>
      </div>
    </div>

    <NavMenu />
    <div class="homepage-btn">
      <router-link to="/">
        <div class="container-fluid">
          <h1 class="large-text name">STUDIO OSCAR MURILLO</h1>
        </div></router-link
      >
    </div>
  </div>
</template>



<style scoped lang="scss">
@media (min-width: 768px) {
  .home-header {
    font-size: 12em !important;
  }
  .line {
    border-bottom: 1em solid white !important;
  }
}
.line {
  width: 100%;
  border-bottom: 0.8em solid white;
}

.home-header {
  font-size: 8em;
  line-height: 0.8em;
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
}
.homepage-btn {
  position: relative;
  bottom: 0;
  margin-top: auto;
}
</style>
