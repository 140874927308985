<template>
  <div class="row mb-3">
    <div class="col-12 mt-md-0 mt-1 d-flex justify-content-between">
      <div class="large-text">
        <router-link to="/">AYT:</router-link> {{ pageName }}
      </div>
      <div @click="changeRouteTo">
        <div class="mt-2 mt-md-4" id="nav-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="line"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pageName: String,
  },
  methods: {
    changeRouteTo() {
      if (this.$route.name === "ShirtDetailView") {
        this.$router.push("/shirt");
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
a,
a:hover {
  color: white;
  text-decoration: none;
}
@media (min-width: 768px) {
  .line {
    border-bottom: 0.5em solid white !important;
  }
  #nav-icon {
    width: 50px !important;
  }
  #nav-icon span {
    height: 4px !important;
  }
  #nav-icon span:nth-child(2) {
    top: 15px !important;
  }
  #nav-icon span:nth-child(3) {
    top: 30px !important;
  }
  #nav-icon.open span:nth-child(1) {
    left: 14px !important;
  }
  #nav-icon.open span:nth-child(3) {
    left: 14px !important;
    top: 30px !important;
  }
  #nav-icon.open span {
    width: 80% !important;
  }
}

.line {
  width: 100%;
  border-bottom: 0.3em solid white;
}

#nav-icon {
  width: 25px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  /* -webkit-transition: 0.2s ease-in-out !important;
  -moz-transition: 0.2s ease-in-out !important;
  -o-transition: 0.2s ease-in-out !important;
  transition: 0.2s ease-in-out !important; */
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;

  opacity: 1;
  right: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  /* -webkit-transition: 0.15s ease-in-out !important;
  -moz-transition: 0.15s ease-in-out !important;
  -o-transition: 0.15s ease-in-out !important;
  transition: 0.2s ease-in-out !important; */
}

#nav-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 2px;
  left: 4px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  left: 4px;
}
#nav-icon.open span {
  width: 90%;
}
</style>