<template>
  <div @click="accept" v-if="isOpen" class="cookie">
    <div class="container-fluid d-flex justify-content-between">
      <div class="cookie_content col-11 my-md-3 my-2">
        <p class="p-0 m-0">
          {{ message }}
        </p>
      </div>
      <div class="col-1 d-flex justify-content-end align-items-center">
        <div class="cookie_button accept my-md-3 my-0 pe-2">X</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CookieBanner",
  props: {
    buttonTextAccept: {
      type: String,
      default: "Accept",
    },
    buttonTextDeny: {
      type: String,
      default: "Deny",
    },
    message: {
      type: String,
      default:
        "For an optimal experience, please use Google Chrome browser and an up to date device",
    },
    fullyloaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    fullyloaded() {
      if (this.fullyloaded) {
        if (!this.getBanner() === true) {
          setTimeout(() => {
            this.isOpen = true;
          }, 1000);
        }
      }
    },
  },
  methods: {
    getBanner() {
      return localStorage.getItem("Experience:accepted", true);
    },
    accept() {
      this.isOpen = false;
      localStorage.setItem("Experience:accepted", true);
    },
  },
};
</script>
<style scoped>
.cookie {
  position: fixed;
  background: #000000;
  z-index: 100;
  width: 100%;
  bottom: 0;
  border-top: 1px solid white;
}
.cookie:hover {
  cursor: pointer;
}

.cookie_content {
  text-align: left;
  color: #ffffff;
}

.cookie_button.accept {
  text-align: center;
  color: #ffffff !important;
  cursor: pointer;
}
@media (min-width: 768px) {
  p {
    font-size: 1.2em !important;
    line-height: 1em !important;
  }
}
p {
  font-size: 0.9em;
  line-height: 1em;
}
</style>